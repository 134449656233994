class RouteController {
  #routeName = "r";
  #params;
  #route;

  constructor() {
    this.#params = new URL(window.location.href).searchParams;
    this.#route = this.#params.get(this.#routeName);
  }

  #addQuery(query) {
    if (query) {
      const queryParams = new URLSearchParams(window.location.search);
      queryParams.set(this.#routeName, query);
      window.history.replaceState(null, null, "?" + queryParams.toString());
    }
  }

  checkAndUpdateCurrentRoute(handleNavigationRoute) {
    if (this.#route) {
      handleNavigationRoute(this.#route);
    }
  }

  updateOnRouting(slug) {
    this.#addQuery(slug);
  }
}

export default RouteController;
