import React, { useEffect, useRef, useState } from "react";
import { graphql } from "gatsby";
import Layout from "../layouts/LayoutIframe";
import Seo from "../components/Seo";
import OgImg from "../assets/images/raster/components/common/course-items.png";
import Navigation from "../components/common/webinars/Navigation";
import RouteController from "../controllers/webinars/routeController";
import WaModeController from "../controllers/webinars/waModeController";
import CheckLicenseController from "../controllers/webinars/checkLicenseController";
import loadable from "@loadable/component";

const Courses = loadable(() =>
  import("../components/courses-templates/Courses")
);

const Masterclasses = loadable(() =>
  import("../components/masterclasses-templates/Masterclasses")
);

const FilterPanel = loadable(() =>
  import("../components/masterclasses-templates/FilterPanel")
);

const University = loadable(() =>
  import("../components/university-list-templates/University")
);

const SeeDetails = loadable(() =>
  import("../components/university-details-templates/SeeDetails")
);

const waModeController =
  typeof window !== "undefined" && new WaModeController();
const checkLicenseController =
  typeof window !== "undefined" && new CheckLicenseController();
const routeController = typeof window !== "undefined" && new RouteController();

const TeWT = ({ data, pageContext }) => {
  const {
    allContentfulSectionDescription,
    allContentfulCoursesItem,
    contentfulMasterClassesFilterPanel,
    allContentfulMasterClassesItem,
    allContentfulUniversityItem,
    allContentfulUniversityDetailsPage,
  } = data;

  const {
    ctaBySlug,
    defaultSlug,
    paidItemsId,
    freeItemsId,
    sectionDescriptionListId,
    cnItemsId,
    pageInfo,
  } = pageContext;

  const [getPlayerState, setPlayerState] = useState({
    isShowYoutubeModal: false,
    urlYoutubeModal: "",
    isLicense: false,
    isOpenPricing: false,
    isWaMode: true,
    isCnUser: false,
    filter: "",
  });

  const [getRouteState, setRouteState] = useState({
    slug: "amazon-private-label-course",
    cta: ctaBySlug[defaultSlug],
  });

  const { slug } = getRouteState;

  const page = pageInfo[slug];
  const isMasterClassesPage = slug === "free-amazon-fba-masterclasses";
  const isUniversityDetailsPage = slug.includes("university/");
  const universityData = allContentfulUniversityDetailsPage.edges.filter(
    (d) => d.node.contentful_id === page.contentful_id
  )?.[0]?.node;

  const updateRouteState = (key, value) => {
    setRouteState((prevState) => {
      return {
        ...prevState,
        [key]: value,
      };
    });
  };

  const offerRef = useRef(null);

  useEffect(() => {
    checkLicenseController.checkLicense(updatePlayerState);
    routeController.checkAndUpdateCurrentRoute(handleNavigationRoute);
  }, []);

  useEffect(() => {
    routeController.updateOnRouting(slug);
  }, [slug]);

  useEffect(() => {
    updatePlayerState("isShowYoutubeModal", !!getPlayerState.urlYoutubeModal);
  }, [getPlayerState.urlYoutubeModal]);

  const handleWaInitiating = () => {
    waModeController.init(updatePlayerState, getRouteState);
  };

  const handleNavigationRoute = (slug) => {
    updateRouteState("slug", slug);
    updateRouteState("cta", ctaBySlug[slug]);
  };

  const updatePlayerState = (key, value) => {
    setPlayerState((prevState) => {
      return {
        ...prevState,
        [key]: value,
      };
    });
  };

  return (
    <Layout withTagManager={false}>
      <Seo
        title={page.title}
        metaTitle={page.metaTitle}
        keywords={page.keywords}
        description={page.metaDescription}
        page="webinars"
        manifest="browserconfig.xml"
        ogImg={page.metaImage ? page.metaImage.file.url : OgImg}
      />
      <Navigation
        isWaMode={getPlayerState.isWaMode}
        mainTitle={getRouteState.cta}
        items={pageContext.navigationItems}
        currentPage={getRouteState.slug}
        handleRouteChange={handleNavigationRoute}
        subLink={isUniversityDetailsPage ? universityData.courseTitle : ""}
        isBlueView
      />
      {(getRouteState.slug === "amazon-private-label-course" ||
        getRouteState.slug === "amazon-seller-training-courses") && (
        <Courses
          onMount={handleWaInitiating}
          sectionDescriptions={allContentfulSectionDescription.edges.filter(
            (e) => e.node.id === sectionDescriptionListId[getRouteState.slug]
          )}
          items={allContentfulCoursesItem.edges}
          getPlayerState={getPlayerState}
          getRouteState={getRouteState}
          paidList={paidItemsId[getRouteState.slug]}
          freeList={freeItemsId[getRouteState.slug]}
          cnList={cnItemsId[getRouteState.slug]}
          updatePlayerState={updatePlayerState}
          h1={page.h1}
        />
      )}
      {isMasterClassesPage && (
        <>
          <FilterPanel
            filter={getPlayerState.filter}
            updatePlayerState={updatePlayerState}
            info={contentfulMasterClassesFilterPanel}
          />
          <Masterclasses
            onMount={handleWaInitiating}
            sectionDescriptions={allContentfulSectionDescription.edges.filter(
              (e) => e.node.id === sectionDescriptionListId[getRouteState.slug]
            )}
            h1={page.h1}
            items={allContentfulMasterClassesItem.edges}
            getPlayerState={getPlayerState}
            updatePlayerState={updatePlayerState}
            paidList={paidItemsId[getRouteState.slug]}
            freeList={freeItemsId[getRouteState.slug]}
          />
        </>
      )}
      {getRouteState.slug === "university" && (
        <University
          onMount={handleWaInitiating}
          listOrder={freeItemsId[getRouteState.slug]}
          items={allContentfulUniversityItem.edges}
          updatePlayerState={updatePlayerState}
          isWaMode={getPlayerState.isWaMode}
          handleRouteChange={handleNavigationRoute}
        />
      )}
      {isUniversityDetailsPage && (
        <SeeDetails
          handleRouteChange={handleNavigationRoute}
          onMount={handleWaInitiating}
          data={universityData}
          updatePlayerState={updatePlayerState}
          h1={page.h1}
          offerRef={offerRef}
          isWaMode={getPlayerState.isWaMode}
          heightPage={getPlayerState.heightPage}
        />
      )}
    </Layout>
  );
};

export const pageQuery = graphql`
  query webinarsPageQuery {
    allContentfulCoursesItem {
      edges {
        node {
          id
          title
          lesson
          paid
          image {
            gatsbyImageData(
              layout: CONSTRAINED
              placeholder: BLURRED
              formats: [AUTO, WEBP]
            )
          }
          videoUrl
          description {
            raw
          }
          button
          blockingButton
          timecodeDescriptionList
          timecodeList
        }
      }
    }
    allContentfulSectionDescription {
      edges {
        node {
          id
          freeDescription {
            raw
          }
          paidDescription {
            raw
          }
          cnDescription {
            raw
          }
        }
      }
    }
    allContentfulMasterClassesItem {
      edges {
        node {
          id
          title
          topics
          paid
          image {
            gatsbyImageData(
              layout: CONSTRAINED
              placeholder: BLURRED
              formats: [AUTO, WEBP]
            )
          }
          videoUrl
          description {
            raw
          }
          button
        }
      }
    }
    contentfulMasterClassesFilterPanel {
      title
      topics
    }
    allContentfulUniversityItem {
      edges {
        node {
          title
          id
          description {
            raw
          }
          person {
            nameSurname
            videoUrl
            photo {
              gatsbyImageData(
                layout: CONSTRAINED
                placeholder: BLURRED
                formats: [AUTO, WEBP]
              )
            }
            description {
              raw
            }
          }
          pageRef {
            slug
          }
          price
          discountPrice
          alternativePersonName
          alternativePersonDescription {
            raw
          }
        }
      }
    }
    allContentfulUniversityDetailsPage {
      edges {
        node {
          contentful_id
          courseTitle
          courseDescription {
            raw
          }
          person {
            nameSurname
            videoUrl
            photo {
              gatsbyImageData(
                layout: CONSTRAINED
                placeholder: BLURRED
                formats: [AUTO, WEBP]
              )
            }
            description {
              raw
            }
          }
          offerDescription {
            raw
          }
          offerBonuses {
            raw
          }
          offerBonusesSpacingBetweenListItems
          offerTools
          offerToolsSpacingBetweenListItems
          offerPrice
          offerDiscount
          offerUrl
        }
      }
    }
  }
`;

export default TeWT;
